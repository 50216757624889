import { Checkbox, IconButton } from '@mui/material';
import { Info } from '@mui/icons-material';

/**
 * Creates the column definitions for the participants grid
 * @param {Object} params Configuration object for the columns
 * @param {Function} params.onCheckboxChange Callback for checkbox changes
 * @param {Function} params.onInfoClick Callback for info button clicks
 * @param {Array} params.userDevices Array of selected device IDs
 * @returns {Array} Array of column definitions
 */
export const getParticipantColumns = ({ onCheckboxChange, onInfoClick, userDevices }) => [
  {
    field: 'selected',
    headerName: 'Select',
    width: 100,
    valueGetter: (value, row) => userDevices.includes(row.fitbitData?.user_id),
    renderCell: (params) => (
      <Checkbox
        checked={userDevices.includes(params.row.fitbitData?.user_id)}
        onChange={(e) => onCheckboxChange(params.row.fitbitData?.user_id, e.target.checked)}
      />
    ),
  },
  {
    field: 'uid',
    headerName: 'Participant ID',
    width: 200
  },
  {
    field: 'fitbitId',
    headerName: 'Fitbit ID',
    width: 100,
    valueGetter: (value, row) => {
      return row?.fitbitData?.user_id || 'N/A';
    }
    // ,
    // renderCell: (params) => {
    //   return params?.row?.fitbitData?.user_id || 'N/A'
    // }
  },
  {
    field: 'pId',
    headerName: 'Prolific ID',
    width: 230,
  },
  {
    field: 'devices',
    headerName: 'Devices',
    width: 150,
    valueGetter: (value, row) => {
      return row?.devices?.join(', ');
    },
    renderCell: (params) => params?.row?.devices?.join(', ') || 'N/A',
    // renderCell: (params) => {
    //   return params?.row?.devices?.map((device) => device.deviceVersion).join(', ') || 'N/A';
    // },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    renderCell: (params) => (
      <IconButton
        onClick={() => onInfoClick(params.row.uid)}
        size="small"
      >
        <Info />
      </IconButton>
    ),
  },
];
