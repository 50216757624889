import PropTypes from 'prop-types';
import { Typography } from '../../../node_modules/@mui/material/index';

const LOOKER_URL = 'https://lookerstudio.google.com/embed/reporting/2dbeea4b-1431-45a9-9670-1730c819ee00/page/kyN7D';

function InsightsPanel(props) {
  // Project data passed as prop
  const { project } = props;

  return (
    <div>
      <Typography variant="h3">Insights for project <code>{project?.name}</code></Typography>
      <iframe 
        title="Looker Studio Dashboard" 
        src={LOOKER_URL} 
        width="800" 
        height="600" 
        frameBorder="0" 
        style={{border: 'none'}}
        sandbox="allow-scripts allow-same-origin"  //Important security setting, restrict access as appropriate
      />
    </div>
  );
}

InsightsPanel.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dataFields: PropTypes.arrayOf(PropTypes.string),
    timeRange: PropTypes.string,
    supportedChartTypes: PropTypes.arrayOf(PropTypes.string),
    registeredParticipants: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
};

// Enhanced PropTypes definition
InsightsPanel.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    dataFields: PropTypes.arrayOf(PropTypes.string),
    timeRange: PropTypes.string,
    supportedChartTypes: PropTypes.arrayOf(PropTypes.string),
    registeredParticipants: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default InsightsPanel;