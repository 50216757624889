import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { getParticipantColumns } from './columns';
import ParticipantsToolbar from './ParticipantsToolbar';

/**
 * Grid component for displaying participants
 */
function ParticipantsGrid({
  participants,
  userDevices,
  onCheckboxChange,
  onInfoClick,
  onRefresh,
  isLoading
}) {
  const columns = getParticipantColumns({
    onCheckboxChange,
    onInfoClick,
    userDevices
  });

  return (
    <Box sx={{ width: '100%', height: '90vh' }}>
      <DataGrid
        rows={participants}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection={false}
        disableSelectionOnClick
        loading={isLoading}
        getRowId={(row) => row.uid}
        components={{
          Toolbar: ParticipantsToolbar
        }}
        componentsProps={{
          toolbar: { onRefresh }
        }}
        sx={{
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
      />
    </Box>
  );
}

ParticipantsGrid.propTypes = {
  participants: PropTypes.array.isRequired,
  userDevices: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onInfoClick: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ParticipantsGrid;
