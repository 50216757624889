import { Button, Stack } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import PropTypes from 'prop-types';

/**
 * Custom toolbar component for the participants grid
 * @param {Object} props Component props
 * @param {Function} props.onRefresh Callback for refresh button click
 */
function ParticipantsToolbar({ onRefresh }) {
  return (
    <GridToolbarContainer>
      <Stack direction="row" spacing={2}>
        <Button onClick={onRefresh}>Refresh</Button>
        <GridToolbarExport />
      </Stack>
    </GridToolbarContainer>
  );
}

ParticipantsToolbar.propTypes = {
  onRefresh: PropTypes.func.isRequired,
};

export default ParticipantsToolbar;
