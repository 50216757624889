import React from 'react';
import { Box, Button, CircularProgress, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Component with props using proper destructuring
function ProlificRegistrationStepper({
  // pid ='', // default value
  pid,
  activeStep,
  loading,
  participantId,
  onPidChange,
  onStorePid,
  onInitiateFitbitAuth,
  onRenewRegistration,
}) {
  // Component logic using passed props
  const steps = ['Prolific ID', 'Fitbit Connection', 'Completion Code'];

  if (loading) {
    return (<CircularProgress />);
  }

  return (
    <Box>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <Box>
          <Typography variant="body" gutterBottom>
            Please enter your <b>Prolific ID</b> below and press <b>Next</b>.
          </Typography>
          <TextField
            label="Prolific ID"
            value={pid || ''}
            onChange={(e) => onPidChange(e.target.value)}
            fullWidth
            margin="normal"
            error={(pid && pid.length < 24)}
            helperText={pid && pid.length < 24 ? 'Prolific ID must be at least 24 characters long' : ''}
            inputProps={{ minLength: 24 }}
            sx={{ mb: 4 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={onStorePid} disabled={!pid || pid.length < 24} >
              Next
            </Button>
          </Box>
        </Box>
      )}

      {(activeStep === 1 || activeStep === 2) && (
        <Box>
          <Typography variant="body" gutterBottom>
            Press the <b>Connect to Fitbit</b> button. You will be redirected to the Google Fitbit page.
          </Typography>
          <Box paddingTop={"32px"}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button variant="outlined" color="secondary" onClick={onRenewRegistration} >
                Back
              </Button>
              <Button variant="contained" onClick={onInitiateFitbitAuth}>
                Connect to Fitbit
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {activeStep >= 3 && (
        <Box>
          <Typography variant="h5" gutterBottom>
            You are all set!
          </Typography>
          <Typography variant="body" fontSize={"1.2em"}>
            Your completion code is: <code>{participantId}</code>
          </Typography>
          <br />
          <Typography variant="body" fontSize={"1.2em"}>
            Please copy and paste this code in Qualtrics to finish the survey.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

// Define prop types for the component
ProlificRegistrationStepper.propTypes = {
  pid: PropTypes.string,
  activeStep: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onPidChange: PropTypes.func.isRequired,
  onStorePid: PropTypes.func.isRequired,
  onInitiateFitbitAuth: PropTypes.func.isRequired,
  onRenewRegistration: PropTypes.func.isRequired,
  participantId: PropTypes.string,
};

export default React.memo(ProlificRegistrationStepper);
