/**
 * Component for displaying detailed participant information and data visualization.
 * This component shows participant profile, device information, and historical data in a grid format.
 *
 * Features:
 * - Displays participant basic information (ID, Prolific ID, FitBit User ID)
 * - Shows FitBit token and authentication status
 * - Visualizes participant data fetch history in a DataGrid
 * - Lists participant's FitBit devices and their status
 * - Allows switching between different participants in the same project
 * - Provides device refresh functionality
 *
 * @component
 * @example
 * ```jsx
 * <ShowParticipant />
 * ```
 *
 * @returns {JSX.Element} A container with participant information, data grid, and device list
 *
 * @requires react
 * @requires react-router-dom
 * @requires @mui/material
 * @requires firebase/firestore
 * @requires firebase/functions
 */
// disable eslint to allow unused variables for the file
/* eslint-disable no-unused-vars */
import { Autocomplete, TextField, Typography, Button, Container, Box, Stack, Paper } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { DataGrid } from '@mui/x-data-grid';
import { useAlert } from "../../context/AlertContext";
import { db, functions } from "../../firebase";
import { convertDateToReadableString } from "../utils/utils";
import { Check as CheckIcon, Close as CloseIcon, Circle as CircleIcon} from "@mui/icons-material";


export default function ShowParticipant() {

  const { participantId, projectId } = useParams();
  // Loading
  const [isLoading, setIsLoading] = React.useState(false);
  // Participant profile from the fitbit API
  const [participantProfile, setParticipantProfile] = React.useState({});
  // Participant data from the firstore db
  const [participantData, setParticipantData] = React.useState({});
  // Project participants from the firstore db via functions
  const [projectParticipants, setProjectParticipants] = React.useState([]);
  // Fetch job history (manual + cron) of the project from the firstore db via functions
  const [fetchHistory, setFetchHistory] = React.useState([]);
  // Participant cron fetch jobs history of the project, should be computed from cronJobHistory
  const [participantHistory, setParticipantHistory] = React.useState([]);
  // Participant introspection data from the fitbit API
  const [participantIntrospection, setParticipantIntrospection] = React.useState({});
  // Participant devices
  const [participantDevices, setParticipantDevices] = React.useState([]);

  // Columns and rows for the DataGrid component
  const [columns, setColumns] = React.useState([
    { field: 'col1', headerName: 'Endpoint', width: 150 }
  ]);
  const [rows, setRows] = React.useState([]);

  // Alerts
  const { addAlert } = useAlert();
  // Navigation
  const navigate = useNavigate();


  console.log("ShowParticipant: %o %o", participantId, projectId);

  React.useEffect(() => {
    setIsLoading(true);
    const participantRef = doc(db, "participants", participantId);
    getDoc(participantRef).then((docSnap) => {
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setParticipantData(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log(`No such document! for ${participantId}`);
        addAlert("error", `No such document! for ${participantId}`);
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
    setIsLoading(false);
  }, [participantId, addAlert]);

  // Get all active participants of the project
  React.useEffect(() => {
    setIsLoading(true);
    try {
      const getProjectParticipants = httpsCallable(functions, "getProjectParticipants");
      getProjectParticipants({ projectId: projectId, activeOnly: true }).then((result) => {
        // console.log("getProjectParticipants: %o", result.data?.participants);
        setProjectParticipants(result.data?.participants);
      }).catch((error) => {
        console.log("Error getting project participants:", error);
        addAlert("error", "Error getting project participants");
      });
      const fetchCronJobs = async () => {
        try {
          const cronHistoryItems = await getFetchJobsByProjectId(projectId);
          console.log("Fetched Cron Jobs:", cronHistoryItems);
          setFetchHistory(cronHistoryItems);
          const gridData = transformCronHistoryForDataGrid(cronHistoryItems, participantId);
          console.log("Transformed Participant History:", gridData);
          setColumns(gridData.columns);
          setRows(gridData.rows);

          introspectParticipant(participantId).then((data) => {
            console.log("Introspected Participant:", data);
            setParticipantIntrospection(data);
          }).catch((err) => {
            console.error("Error introspecting participant:", err);
            //addAlert("error", "Error introspecting participant");
          });
          const devices = await fetchParticipantDevices(participantId);
          console.log("Fetched Participant Devices:", devices);
          setParticipantDevices(devices);
        } catch (err) {
          console.error("Error getting cron jobs:", err);
          addAlert("error", "Error getting cron jobs");
        }
      };
      fetchCronJobs();
    } catch (err) {
      console.error("Error getting project participants:", err);
      addAlert("error", "Error getting project participants");
    }

    setIsLoading(false);
    // eslint-disable-next-line
  }, [projectId, participantId, addAlert]);

  /**
   * Introspects a participant's token using Firebase Cloud Functions
   * @async
   * @param {string} participantId - The unique identifier of the participant
   * @returns {Promise<Object|null>} The introspection data if successful, null if error occurs
   * @throws {Error} When the Firebase function call fails
   */
  const introspectParticipant = async (participantId) => {
    try {
      const introspectCallable = httpsCallable(functions, "introspectToken");
      const response = await introspectCallable({ uid: participantId });
      const data = await response.data
      return data;
    } catch (error) {
      console.error("Error introspecting participant:", error);
      return null;
    }
  };

  /**
   * Refreshes the list of devices associated with a participant.
   * Fetches the devices from the server and updates the local state.
   *
   * @param {string} participantId - The unique identifier of the participant
   * @returns {Promise<void>} A promise that resolves when the devices are refreshed
   * @throws {Error} When there is an error fetching the participant devices
   */
  const refreshParticipantDevices = async (participantId) => {
    console.log("Refreshing participant devices...", participantId);
    try {
      const devices = await fetchParticipantDevices(participantId);
      console.log("Fetched Participant Devices:", devices);
      setParticipantDevices(devices);
    } catch (error) {
      console.error("Error refreshing participant devices:", error);
    }
  };

  /**
   * Fetches the devices for a participant from Firestore.
   * @param {string} participantId The ID of the participant to fetch devices for.
   * @returns {Promise<Array<Object>>} A promise that resolves with an array of device objects, or an empty array if no devices are found.
   */
  const fetchParticipantDevices = async (participantId) => {
    try {
      const devicesCallable = httpsCallable(functions, "getParticipantDevices");
      const response = await devicesCallable({ participantId: participantId });
      const data = await response.data;
      console.log("Fetched Participant Devices:", data);
      return data;
    } catch (error) {
      console.error("Error getting participant devices:", error);
      return [];
    }
  };

  /**
   * Retrieves fetchCronJobs for a specific projectId.
   *
   * @async
   * @param {string} projectId The ID of the project to retrieve fetchCronJobs for.
   * @returns {Promise<Array<Object>>} A promise that resolves with an array of fetchCronJob objects, or an empty array if no jobs are found.
   * @throws {Error} If there is an error retrieving the fetchCronJobs.
   */
  const getFetchJobsByProjectId = async (projectId, type = "manual cron") => {
    try {
      const allJobs = [];
      if (type.includes("cron")) {
        const cronJobsRef = collection(db, "fetchCronJobs");
        const q = query(cronJobsRef, where("projectId", "==", projectId));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          allJobs.push(doc.data());
        });
      }
      if (type.includes("manual")) {
        const manualJobsRef = collection(db, "fetchJobs");
        const q = query(manualJobsRef, where("projectId", "==", projectId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          allJobs.push(doc.data());
        });
      }
      return allJobs;
    } catch (error) {
      console.error("Error getting fetchCronJobs:", error);
      throw new Error("Error getting fetchCronJobs");
    }
  };

  /**
   * Enum for endpoint status values
   * @readonly
   * @enum {string}
   */
  const STATUS = {
    SUCCESS: 'success',
    ERROR: 'error',
    MISSING: 'missing'
  };

  /**
   * Custom cell renderer for status icons
   * @param {Object} params - GridRenderCellParams from DataGrid
   * @returns {JSX.Element} Rendered icon based on status
   */
  const renderStatusCell = (params) => {
    switch (params.value) {
      case STATUS.SUCCESS:
        return <CheckIcon sx={{ color: 'green' }} />;
      case STATUS.ERROR:
        return <CloseIcon sx={{ color: 'red' }} />;
      case STATUS.MISSING:
        return <CircleIcon sx={{ color: 'gray' }} />;
      default:
        return null;
    }
  };

  /**
   * Transforms cron history data into a format suitable for DataGrid
   */
  const transformCronHistoryForDataGrid = useCallback((cronHistoryItems, participantId) => {
    // Initialize result object with columns and rows
    const result = {
      columns: [
        { field: 'id', hide: true },
        { field: 'col1', headerName: 'Endpoint', width: 150 }
      ],
      rows: []
    };

    // Get the participant's device history
    const deviceHistory = cronHistoryItems.flatMap(item =>
      item.devices?.filter(device => device.participantUid === participantId) || []
    );

    if (deviceHistory.length === 0) return result;

    // Get all unique dates from all endpoints
    const allDates = deviceHistory.flatMap(device =>
      device.endpoints?.map(endpoint =>
        convertDateToReadableString(endpoint.date)
      ) || []
    );
    const sortedUniqueDates = [...new Set(allDates)].sort();

    // Create columns for each date
    sortedUniqueDates.forEach((date, index) => {
      result.columns.push({
        field: `col${index + 2}`,
        headerName: date,
        width: 90,
        renderCell: renderStatusCell
      });
    });

    // Get all unique sensor IDs
    const allSensorIds = deviceHistory.flatMap(device =>
      device.endpoints?.map(endpoint => endpoint.sensorId) || []
    );
    const uniqueSensorIds = [...new Set(allSensorIds)].sort();

    // Create rows for each sensor ID
    uniqueSensorIds.forEach((sensorId, rowIndex) => {
      const row = {
        id: rowIndex + 1,
        col1: sensorId
      };

      // Add status for each date
      sortedUniqueDates.forEach((date, colIndex) => {
        const endpoint = deviceHistory.flatMap(device =>
          device.endpoints?.filter(ep =>
            ep.sensorId === sensorId &&
            convertDateToReadableString(ep.date) === date
          ) || []
        )[0];

        row[`col${colIndex + 2}`] = endpoint
          ? (endpoint.status === 200 ? STATUS.SUCCESS : STATUS.ERROR)
          : STATUS.MISSING;
      });
      result.rows.push(row);
    });

    return result;
  // eslint-disable-next-line
  },[]); // Remove unnecessary dependencies

  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    return (
      <Container sx={{ mt: 1 }}>
        <Typography variant="h2">Participant Information</Typography>

        {/* Autocomplete component for participant selection */}
        <Autocomplete
          options={projectParticipants}
          getOptionLabel={(option) => option.uid || ''}
          style={{ width: 300, marginBottom: 20 }}
          value={projectParticipants.find((participant) => participant.uid === participantId) || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Participant"
              variant="outlined"
            />
          )}
          onChange={(event, newValue) => {
            if (newValue) {
              // Navigate to the selected participant's page
              navigate(`/admin/participants/${newValue.uid}/project/${projectId}`);
            }
          }}
        />

        <hr />
        <Typography variant="h4">Participant Information</Typography>
        <Typography variant="body1">Participant ID: {participantId}</Typography>
        <Typography variant="body1">Project ID: {projectId}</Typography>
        <Typography variant="body1">Prolific ID: {participantData?.pId}</Typography>
        <Typography variant="body1">FitBit User ID: {participantData?.fitbitData?.user_id}</Typography>
        <Typography variant="body1">FitBit Last Token Updated At:
          {participantData?.fitbitData?.timestamp?.toDate()?.toLocaleString()}
        </Typography>
        <Typography variant="body2">Introspection:
          <code>{JSON.stringify(participantIntrospection, null, 4)}</code>
        </Typography>

        <hr />
        <div style={{ height: '100vh', width: "100%" }}>
          <DataGrid rows={rows} columns={columns} />
        </div>
        {participantDevices.length > 0 && (
          <Box>
            <Paper elevation={0} sx={{ p: 3 }}>
              <Typography variant="h4">Participant Devices</Typography>
              {participantDevices.map((device) => (
                <Typography variant="body1" key={device?.id}>
                  Device Type: { device?.type} - Device Version: {device?.deviceVersion} - Last Sync: {device?.lastSyncTime} - BatteryLevel: {device?.batteryLevel}
                </Typography>
              ))}
            </Paper>
          </Box>
        )}
        <Stack direction={'row'} spacing={2} sx={{ my: 2 }}>
          <Button variant="contained" color="secondary" onClick={() => refreshParticipantDevices(participantId)}>
            Refresh Devices
          </Button>
        </Stack>
      </Container>
    );
  }
}

